<template>
  <transition name="fade">
    <div class="mask" v-if="visible">
      <div
        class="gee-test-dialog"
        id="__GEE_TEST__"
      >
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('geeTest', ['visible']),
  },

  mounted() {

  },

  methods: {
    ...mapActions('geeTest', ['GeeTest']),
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
}

.gee-test-dialog{
  // padding: 20px 40px;
  // width: 370px;
  // height: 228px;
  // box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: #fff;
  // margin-left: -185px;
  // margin-top: -114px;
  // box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
