
<template>
  <div class="page-layout">
    <!-- <app-navbar></app-navbar> -->
    <app-main></app-main>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import { AppNavbar, AppMain, AppFooter } from './components';

export default {
  components: {
    AppNavbar,
    AppMain,
    AppFooter,
  },
};
</script>

<style lang="scss">
  html {
    height: 100%;
  }
  body {
    height: 100%;
  }
  .page-layout {
    height: 100%;
  }

</style>
