import Vue from 'vue';

import router from '@pc/router';
import App from '@pc/views';
import store from '@/store';
import filters from '@/filters';
// import '@/common/gt.js';
import 'normalize.css';
import '@pc/styles/index.scss';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  position: 'top-center',
  duration: 3000,
  className: 'toast-wrapper',
});

if (process.env.VUE_APP_BUILD_ENV === 'production') {
  // eslint-disable-next-line
  require('@/common/initSentry.js');
}

/**
 * 初始化字典值，
 */
require('@/initEnums');

Vue.config.productionTip = false;

/**
 * 注册filters
 */
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
