import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@pc/views/layout/Layout';

Vue.use(VueRouter);

export const constantRouterMap = [
  {
    path: '/404',
    component: () => import('@pc/views/errorPage/404'),
    hidden: true,
  },
  {
    path: '',
    component: Layout,
    redirect: 'login',
    children: [{
      path: 'login',
      component: () => import('@pc/views/login/index'),
      name: 'login',
    }, {
      path: 'register',
      component: () => import('@pc/views/register/index'),
      name: 'register',
    }, {
      path: 'forget',
      component: () => import('@pc/views/findPassword/index'),
      name: 'forget',
      redirect: '/forget/step_1',
      children: [{
        path: 'step_1',
        component: () => import('@pc/views/findPassword/components/Step1'),
        name: 'forget/step_1',
        props: {
          step: 1,
        },
      }, {
        path: 'step_2',
        component: () => import('@pc/views/findPassword/components/Step2'),
        name: 'forget/step_2',
      }, {
        path: 'step_3',
        component: () => import('@pc/views/findPassword/components/Step3'),
        name: 'forget/step_3',
      }],
    }],
  },
  {
    path: '*',
    component: () => import('@pc/views/errorPage/404'),
    hidden: true,
  },
];

export default new VueRouter({
  mode: 'history',
  // mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});
