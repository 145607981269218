<template>
  <section class="app-main">
    <!-- <transition name="fade-transform" mode="out-in"> -->
      <router-view :key="key"></router-view>
    <!-- </transition> -->
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style scoped>
.app-main {
  position: relative;
  /* overflow: hidden; */
  height: 100%;
}
</style>
