<template>
  <div id="app">
    <router-view></router-view>
    <gee-test-dialog/>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import GeeTestDialog from '@pc/components/GeeTestDialog';

export default {
  components: {
    GeeTestDialog,
  },
  data() {
    return {

    };
  },
  computed: {
    // ...mapState(['inited']),
  },
  // beforeCreate() {
  //   this.$store.dispatch('CheckLogin');
  // },
  name: 'App',
};
</script>


<style>
#app {
  height: 100%;
}
</style>
